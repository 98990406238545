import React from 'react'
import Header from './Header'
import Intro from "./Intro"
import Features from "./Features"

const blocks = {
  header: Header,
  intro: Intro,
  features: Features
}
const Blocks = ({ block }) => {
  if (block.type) {
    const BlockComp = blocks[block.type];
    if (BlockComp) {
      return (<BlockComp data={block} />)
    }
  }
  return (<></>)
}

export default Blocks;
