import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import SEO from '../UI/seo'
import Blocks from '../blocks'
export const PageTemplate = ({ data }) => {
  const blocks = data.blockscollection || [];
  return (
    <Layout>
      <SEO title={data.title} description={data.description} />
      {blocks.map((block, i) => (<Blocks block={block} key={block.type + '-' + i} />))}
    </Layout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.object,
}

const Page = ({ data }) => {
  // const { pagesJson } = data;
  const { markdownRemark: post } = data
  return (
    <PageTemplate data={post.frontmatter} />
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Page

export const pageQuery = graphql`
query PageTemplate($id: String!) {
markdownRemark(id: {eq: $id}) {
  html
    frontmatter {
      title
      blockscollection {
        bigTitle
        blockTemplate
        ctas {
          href
          icon
          label
        }
        id
        subTitle
        type
      }
    }
  }
}

`
