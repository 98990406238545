import React from 'react'
import PreviewCompatibleImage from '../../UI/PreviewCompatibleImage'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeaderWrapper from './style'
import Section from '../../UI/Section'
import { Box } from 'rebass/styled-components'


const Features = ({ data }) => {
  // console.log(data);
  const id = data.id || '';
  const features = data.features || [];
  var settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <HeaderWrapper id={id}>
    <Section>
      <Slider {...settings}>
        {features.map((feature, i) => (
          <Box p="15px" height="250px" key={'feature-' + i}>
            <h4>{feature.name}</h4>
            <Box>
            <PreviewCompatibleImage imageInfo={feature} />
            </Box>
          </Box>
        ))}
      </Slider>
      </Section>
    </HeaderWrapper>
  )
}
export default Features;
