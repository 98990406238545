import React from 'react'
import ReactMarkdown from 'react-markdown'
import Section from '../../UI/Section'
import Container from '../../UI/Container'
const Intro = ({ data }) => {
  // console.log(data);
  const id = data.id || '';
  return (
    <Section id={id}>
    <Container>
      <ReactMarkdown source={data.content} />
      </Container>
    </Section>

  )
}
export default Intro;
