import styled from 'styled-components';
// import { themeGet } from '@styled-system/theme-get';
/* Wiltech wrapper style */
const HeaderWrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  position:relative;
  
`;

export default HeaderWrapper;
