import React from 'react'
import HeaderWrapper from './style'
import Container from '../../UI/Container'

const Header = ({ data }) => {
  // console.log(data);
  const id = data.id || '';
  return (
    <HeaderWrapper id={id} >
    <Container display="flex" alignItems="center">
    <div>
      <h1>{data.bigTitle}</h1>
      <h6>{data.subTitle}</h6>
      </div>
      </Container>
    </HeaderWrapper>

  )
}
export default Header;
