import styled from 'styled-components';
// import { themeGet } from '@styled-system/theme-get';
/* Wiltech wrapper style */
const FeaturesWrapper = styled.div`

  img{
    max-width:100%;
    display:block;
    margin:0 auto;
  }
`;

export default FeaturesWrapper;
